/* istanbul ignore file */

/**
  * DO NOT EDIT!
  * This file was autogenerated by graphql-codegen.
  * Changes may cause incorrect behavior and will be lost when the code is regenerated.
  *
  * Run `npx run codegen` to regenerate.
  */


export type Maybe<T> = T | null;
export type InputMaybe<T> = Maybe<T>;
export type Exact<T extends { [key: string]: unknown }> = { [K in keyof T]: T[K] };
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]?: Maybe<T[SubKey]> };
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]: Maybe<T[SubKey]> };
export type MakeEmpty<T extends { [key: string]: unknown }, K extends keyof T> = { [_ in K]?: never };
export type Incremental<T> = T | { [P in keyof T]?: P extends ' $fragmentName' | '__typename' ? T[P] : never };
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: { input: string; output: string; }
  String: { input: string; output: string; }
  Boolean: { input: boolean; output: boolean; }
  Int: { input: number; output: number; }
  Float: { input: number; output: number; }
  /** The `Date` scalar represents an ISO-8601 compliant date type. */
  Date: { input: string; output: string; }
  /** The `DateTime` scalar represents an ISO-8601 compliant date time type. */
  DateTime: { input: unknown; output: unknown; }
  /** The built-in `Decimal` scalar type. */
  Decimal: { input: unknown; output: unknown; }
  JSON: { input: unknown; output: unknown; }
  /** The `Long` scalar type represents non-fractional signed whole 64-bit numeric values. Long can represent values between -(2^63) and 2^63 - 1. */
  Long: { input: unknown; output: unknown; }
  Void: { input: unknown; output: unknown; }
};

export enum AddressMetadataAllowedSearchResults {
  Area = 'Area',
  City = 'City',
  Country = 'Country',
  District = 'District',
  Locality = 'Locality',
  Market = 'Market',
  Province = 'Province',
  Region = 'Region',
  Street = 'Street',
  ZipCode = 'ZipCode'
}

export enum AddressType {
  Channel = 'Channel',
  Delivery = 'Delivery',
  Foreign = 'Foreign',
  Home = 'Home',
  Kyc = 'Kyc',
  Other = 'Other'
}

export enum ApplyPolicy {
  AfterResolver = 'AFTER_RESOLVER',
  BeforeResolver = 'BEFORE_RESOLVER',
  Validation = 'VALIDATION'
}

export enum ArticleType {
  BeautyEdit = 'BeautyEdit',
  RelatedToProduct = 'RelatedToProduct',
  Undefined = 'Undefined'
}

export enum BackInStockNotificationChannel {
  Email = 'Email',
  Sms = 'Sms'
}

export type BackInStockNotificationSubscriptionInput = {
  /** Email value for notificationChannel.Email */
  readonly email?: InputMaybe<Scalars['String']['input']>;
  /** The product code to watch */
  readonly itemCode?: InputMaybe<Scalars['String']['input']>;
  /** Flag indicating the channel for notification */
  readonly notificationChannel: BackInStockNotificationChannel;
  /** Telephone value for notificationChannel.Telephone */
  readonly telephoneNumber?: InputMaybe<Scalars['String']['input']>;
};

export enum BankBonusPaymentTransferState {
  NothingForTransfer = 'NOTHING_FOR_TRANSFER',
  Pending = 'PENDING',
  Transferred = 'TRANSFERRED',
  Undefined = 'UNDEFINED'
}

export enum CatalogueOfferType {
  Buy = 'Buy',
  Choose = 'Choose',
  ChooseAndChoose = 'ChooseAndChoose',
  ChooseAndGet = 'ChooseAndGet',
  Get = 'Get',
  None = 'None'
}

export enum CatalogueType {
  /** DigitalCurrent catalogue type */
  DigitalCurrent = 'DigitalCurrent',
  /** DigitalNext catalogue type */
  DigitalNext = 'DigitalNext',
  /** LocalCustom catalogue type */
  LocalCustom = 'LocalCustom',
  /** Lookbook catalogue type */
  Lookbook = 'Lookbook',
  None = 'None'
}

export enum ChannelType {
  Mv7 = 'MV7',
  Website = 'Website'
}

export enum CustomerCategory {
  Anonymous = 'Anonymous',
  Consultant = 'Consultant',
  Employee = 'Employee',
  EndConsumer = 'EndConsumer',
  EndCustomer = 'EndCustomer',
  Invitation = 'Invitation',
  Other = 'Other'
}

export enum EWalletType {
  Revolut = 'Revolut'
}

export enum FeedbackBenefitBannerType {
  Cashback = 'Cashback',
  ShareAndEarn = 'ShareAndEarn'
}

export enum Gender {
  Man = 'Man',
  Other = 'Other',
  Woman = 'Woman'
}

export enum LanguageType {
  Alternative = 'Alternative',
  Default = 'Default'
}

export enum LinkType {
  Brand = 'BRAND',
  Campaign = 'CAMPAIGN',
  Catalogue = 'CATALOGUE',
  Category = 'CATEGORY',
  CategoryNavigation = 'CATEGORY_NAVIGATION',
  Custom = 'CUSTOM',
  DigitalCatalogueCurrentNavigation = 'DIGITAL_CATALOGUE_CURRENT_NAVIGATION',
  EditorialArticle = 'EDITORIAL_ARTICLE',
  EditorialCampaign = 'EDITORIAL_CAMPAIGN',
  Frontpage = 'FRONTPAGE',
  Ingredient = 'INGREDIENT',
  Invite = 'INVITE',
  NewProducts = 'NEW_PRODUCTS',
  NovageSkincareAdvisor = 'NOVAGE_SKINCARE_ADVISOR',
  PlPage = 'PL_PAGE',
  Product = 'PRODUCT',
  RegistrationPage = 'REGISTRATION_PAGE',
  SignIn = 'SIGN_IN'
}

export enum MobileAppType {
  None = 'None',
  ToSell = 'ToSell',
  ToShop = 'ToShop'
}

export enum NavigationLinkType {
  AllCategory = 'AllCategory',
  Bestsellers = 'Bestsellers',
  BestsellersInCategory = 'BestsellersInCategory',
  Bottom = 'Bottom',
  BottomCopyright = 'BottomCopyright',
  BottomLinks = 'BottomLinks',
  Catalogue = 'Catalogue',
  Catalogues = 'Catalogues',
  Category = 'Category',
  DigitalCatalogueCurrent = 'DigitalCatalogueCurrent',
  DigitalCatalogueNext = 'DigitalCatalogueNext',
  FocusPage = 'FocusPage',
  FooterColumn = 'FooterColumn',
  FooterColumnItem = 'FooterColumnItem',
  IDontKnowYet = 'IDontKnowYet',
  Icons = 'Icons',
  IconsInCategory = 'IconsInCategory',
  Inspiration = 'Inspiration',
  JoinRewards = 'JoinRewards',
  KeyIngredients = 'KeyIngredients',
  MenuTiles = 'MenuTiles',
  New = 'New',
  None = 'None',
  OurApps = 'OurApps',
  OutletShop = 'OutletShop',
  PaymentMethod = 'PaymentMethod',
  PaymentMethods = 'PaymentMethods',
  PopularBrand = 'PopularBrand',
  PopularBrands = 'PopularBrands',
  PopularLookbook = 'PopularLookbook',
  Qrtr = 'QRTR',
  RewardsShop = 'RewardsShop',
  SampleShop = 'SampleShop',
  Segment = 'Segment',
  SubCategory = 'SubCategory',
  SupportCenter = 'SupportCenter',
  TopNavigation = 'TopNavigation',
  WhatsNewInCategory = 'WhatsNewInCategory'
}

export enum NavigationType {
  AboutTiles = 'AboutTiles',
  Footer = 'Footer',
  InspirationTiles = 'InspirationTiles',
  JoinTiles = 'JoinTiles',
  ShopSection = 'ShopSection',
  TopNavigation = 'TopNavigation'
}

export enum NotificationBannerThemeType {
  Black = 'Black',
  Grey = 'Grey'
}

export enum OlapicTypeEnum {
  Category = 'Category',
  FrontPage = 'FrontPage',
  Gallery = 'Gallery',
  ProductDetails = 'ProductDetails'
}

export enum PlpOrderBy {
  HighestDiscount = 'HighestDiscount',
  HighestPrice = 'HighestPrice',
  HighestRating = 'HighestRating',
  Latest = 'Latest',
  LowestPrice = 'LowestPrice',
  Recommended = 'Recommended'
}

export type PlpRowsFacetFilterInput = {
  /** Gets or sets the active children keys. */
  readonly keys?: InputMaybe<ReadonlyArray<InputMaybe<Scalars['String']['input']>>>;
  /** Gets or sets the facet name. */
  readonly name?: InputMaybe<Scalars['String']['input']>;
};

export enum ProductLabelDomainType {
  Deal = 'Deal',
  Ppa = 'PPA',
  Shopping = 'Shopping'
}

export enum ProductListType {
  Favorite = 'Favorite',
  Personal = 'Personal',
  Private = 'Private',
  Wishlist = 'Wishlist'
}

export enum ProductRelationEnum {
  RecentlyViewed = 'RecentlyViewed',
  Related = 'Related'
}

export enum ProductRelationType {
  RecentlyViewed = 'RecentlyViewed',
  Related = 'Related',
  Undefined = 'Undefined'
}

export enum ProductType {
  Bundle = 'Bundle',
  Composite = 'Composite',
  Fee = 'Fee',
  Product = 'Product',
  Sample = 'Sample'
}

export enum ResponseMessageNewsletterSubsciptionsType {
  Cleaned = 'Cleaned',
  New = 'New',
  Pending = 'Pending',
  Subscribed = 'Subscribed',
  Unsubscribed = 'Unsubscribed'
}

export enum SettingsClientType {
  OnlineReportingGrid = 'OnlineReportingGrid',
  WellnessClub = 'WellnessClub'
}

export enum SettingsFormatType {
  Json = 'Json',
  String = 'String',
  Xml = 'Xml'
}

export type ShareAndEarnUpdatePersonalCodeInput = {
  /** The customer ID of the customer who wants to update the personal code. */
  readonly customerId?: InputMaybe<Scalars['String']['input']>;
  /** The new personal code */
  readonly newPersonalCode?: InputMaybe<Scalars['String']['input']>;
};

export enum ShowType {
  Ended = 'Ended',
  Live = 'Live',
  Scheduled = 'Scheduled'
}

export enum SocialContactType {
  Email = 'Email',
  Facebook = 'Facebook',
  PhoneNumber = 'PhoneNumber',
  VKontakte = 'VKontakte',
  WeChat = 'WeChat',
  WhatsUpNumber = 'WhatsUpNumber'
}

export enum SocialMediaType {
  Facebook = 'Facebook',
  Instagram = 'Instagram',
  Line = 'Line',
  Odnoklassniki = 'Odnoklassniki',
  Pinterest = 'Pinterest',
  Qq = 'QQ',
  Telegram = 'Telegram',
  Twitter = 'Twitter',
  VKontakte = 'VKontakte',
  WeChat = 'WeChat',
  Weibo = 'Weibo',
  YouTube = 'YouTube',
  Youku = 'Youku'
}

export enum SponsorType {
  DirectSponsor = 'DirectSponsor'
}

export enum StorybookColor {
  Blue100 = 'Blue100',
  Brown50 = 'Brown50',
  Brown300 = 'Brown300',
  Brown400 = 'Brown400',
  DefaultContrastText = 'DefaultContrastText',
  Green400 = 'Green400',
  Grey50 = 'Grey50',
  Grey900 = 'Grey900',
  InfoDark = 'InfoDark',
  Pink50 = 'Pink50',
  Pink300 = 'Pink300',
  PrimaryLight = 'PrimaryLight',
  Purple50 = 'Purple50',
  Purple200 = 'Purple200',
  Purple400 = 'Purple400',
  Red50 = 'Red50',
  SecondaryMain = 'SecondaryMain',
  TextPrimary = 'TextPrimary',
  TextSecondary = 'TextSecondary',
  Undefined = 'Undefined',
  Yellow50 = 'Yellow50',
  Yellow400 = 'Yellow400'
}

export enum TelephoneType {
  Business = 'Business',
  Fax = 'Fax',
  Home = 'Home',
  Mobile = 'Mobile'
}

export enum VideoType {
  Mp4 = 'Mp4',
  Youku = 'Youku',
  Youtube = 'Youtube'
}

export enum VisitorSegment {
  Anonymous = 'Anonymous',
  Consultant = 'Consultant',
  DirectorAndAbove = 'DirectorAndAbove',
  EndCustomer = 'EndCustomer',
  Manager = 'Manager',
  Member = 'Member',
  NewConsultant = 'NewConsultant',
  NotDefined = 'NotDefined',
  Spo = 'Spo'
}
