import { Team } from '@ori/testid-generator';

export const APPLICATION_NAME = '@ori/catalogue-ipaper';

/**
 * Name of this app.
 */
export const APP_NAME = 'catalogue-ipaper';

/**
 * Team which owns this SPA.
 */
export const APP_TEAM_NAME: Team = Team.Presentation;
