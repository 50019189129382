// istanbul ignore file -- api
import { useServiceUrls } from '@ori/configuration-manager';
import { DateFormat, formatDate, useCulture } from '@ori/culture';
import { useGraphqlQuery } from '@ori/fetching';
import { useLogger } from '@ori/logger';
import { useHeaders } from '@ori/presentation-http';
import { useCallback, useMemo } from 'react';

import type { CataloguesLandingPageContext } from '../contexts';
import type { CataloguesLandingPageQuery, CataloguesLandingPageQueryVariables } from '../models';
import { cataloguesLandingPageQuery } from '../queries';
import { parseCataloguesLandingPageData } from '../utils';

export const useGetCatalogueLandingPageData = (): CataloguesLandingPageContext => {
  const { graphQlNetUrl } = useServiceUrls(['graphQlNet']);
  const { headers, loading: loadingHeaders } = useHeaders();
  const childLogger = useLogger('useGetCatalogueLandingPageData');
  const { culture, timeZone } = useCulture();
  const formatValidityDate = useCallback(
    (date: Date) => formatDate(date, DateFormat.ShortDate, culture, timeZone),
    [culture, timeZone],
  );
  const onError = useCallback(
    (error: Error) => {
      childLogger.error(new Error('Failed fetch of catalogue landing page data', { cause: error }));
    },
    [childLogger],
  );
  const { data, error } = useGraphqlQuery<CataloguesLandingPageQuery, CataloguesLandingPageQueryVariables>({
    query: cataloguesLandingPageQuery,
    url: graphQlNetUrl,
    headers,
    onError,
    skip: loadingHeaders,
    swrConfig: {
      revalidateIfStale: false,
      revalidateOnFocus: false,
      revalidateOnReconnect: false,
    },
  });

  return useMemo(
    () => ({
      data: data ? parseCataloguesLandingPageData(data, formatValidityDate) : null,
      loading: (data === undefined && error === undefined) || loadingHeaders,
    }),
    [data, error, formatValidityDate, loadingHeaders],
  );
};
